<template>
  <div class="home">
    <section id="form-container" v-if="loading">
      <Loader />
    </section>

    <section id="form-container" v-else-if="!loading && !requestSuccess">
      <h2>Request Your Link</h2>

      <form @submit.prevent="handleSubmit">
        <label class="email" for="email">
          <span>Email</span>
          <input id="email" type="text" v-model="email" required />
          <p v-if="errors.email" class="error">{{ errors.email }}</p>
        </label>
        <button>Request</button>
      </form>

      <div class="warning">
        <p>
          IMPORTANT: This website is intended for Founder Sport Rep use only.
        </p>
        <p>Dealers, please contact your Rep for assistance using Gameplan.</p>
      </div>
    </section>

    <section id="form-container" v-else>
      <h2>Please check your email</h2>
      <p>
        If there's a Founder Sport Rep account asociated with {{ email }} you'll
        get an email with a link to automatically log in to your Gameplan Rep
        Panel.
      </p>
      <p>The link will expire in 15 minutes.</p>
      <p>
        Your existing password still works, should you want to log in with it
        later.
      </p>
      <p>Still need help? Contact us at gameplan@foundersportgroup.com</p>
    </section>
  </div>
</template>

<script>
import axios from "axios";
import { mapActions, mapState } from "vuex";
import Loader from "../components/Utils/Loader.vue";
export default {
  components: { Loader },
  name: "Login",

  data() {
    return {
      email: "",
      loading: false,
      errors: {
        email: "",
      },
      requestSuccess: false,
    };
  },

  computed: {
    ...mapState(["endpoint"]),
    formHasErrors() {
      return this.errors.email.length > 0;
    },
  },

  methods: {
    ...mapActions(["requestLogin"]),

    validateEmail(email) {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    },

    checkForm() {
      //Reset errors
      this.errors = {
        email: "",
      };

      const validEmail = this.validateEmail(this.email);

      if (!validEmail) {
        this.errors["email"] = "Enter a valid email";
      }
    },

    async handleSubmit() {
      this.checkForm();

      if (!this.formHasErrors) {
        try {
          this.loading = true;
          await this.requestAutoLogin();
          this.loading = false;
          this.requestSuccess = true;
        } catch (error) {
          console.log(error.response);
        }
      }
    },

    async requestAutoLogin() {
      const url = this.endpoint + "/magic-login";
      const params = { email: this.email };

      try {
        await axios.post(url, params);
        // console.log(response.data);
      } catch (error) {
        console.log(error.response);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.home {
  display: flex;
  background: url("/images/homepage-bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  height: calc(100vh - 70px);

  #form-container {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 50%;
    height: 40%;
    min-height: 400px;
    max-width: 720px;
    padding: 1% 7%;
    background-color: white;
    margin: auto;

    & > * {
      margin-bottom: 2rem;
    }

    & > p {
      text-align: center;
    }

    h2 {
      text-align: center;
      font-family: "SlateStd";
      font-weight: bold;
    }

    form {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: flex-end;

      label {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-content: center;
        margin-bottom: 0;

        &.email {
          width: 100%;
          margin-bottom: 1.5rem;
        }
        &.password {
          width: 65%;
        }

        input {
          padding: 1rem;
          border-radius: 5px;
          border: 1px solid black;
        }

        p.error {
          margin: 0;
          background: rgb(252, 61, 61);
          color: whitesmoke;
          border-radius: 0 0 5px 6px;
          padding: 0.2rem;
          font-weight: bold;
        }
      }

      button {
        width: 30%;
        background-color: black;
        color: white;
        border: none;
        border-radius: 5px;
        font-weight: bold;
        height: 47px;
        margin-left: auto;
        text-transform: uppercase;
      }
    }

    .warning {
      p {
        text-align: center;
        font-size: 1.3rem;
        margin-bottom: 0.1rem;
      }
    }

    @media (max-width: 768px) {
      width: 90%;
    }
  }

  @media (max-width: 1024px) {
    height: calc(100vh - 50px);
  }
}
</style>